import React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { graphql } from 'gatsby';

import { getImagePreview } from 'utils';

import { Paragraphs, Layout, SEO } from 'components';

const BasicPageTemplate = ({
  location: { pathname: siteUrl },
  data: {
    nodePage: {
      title,
      field_metatags,
      field_automatic_breadcrumbs,
      field_alternative_design,
      relationships: {
        field_content,
        field_image_header,
        field_header_paragraph,
        field_breadcrumbs,
      },
    },
  },
}) => {
  const paragraphHeader = field_header_paragraph.find(
    (item) => item?.internal?.type === 'paragraph__header_paragraph',
  );
  const isAlternativeHeader = field_alternative_design;
  const imageOpenGraphPreview = field_image_header?.localFile?.childImageSharp?.fluid?.src;
  const headerImage = paragraphHeader?.field_image?.localFile?.publicURL;
  const description = paragraphHeader?.field_description;
  const imageUrl = getImagePreview(headerImage, imageOpenGraphPreview);

  return (
    <Layout
      headerData={field_header_paragraph}
      nodeTitle={title}
      isDefaultBreadcrumbs={field_automatic_breadcrumbs}
      customBreadcrumbs={field_breadcrumbs}
      type={description ? `front inner-page ${title === '404' ? 'nf-page' : ''}` : 'basic-page'}
      isAlternativeHeader={isAlternativeHeader}
    >
      <SEO
        title={field_metatags?.title || title}
        description={field_metatags?.description || ''}
        keywords={field_metatags?.keywords || ''}
        robots={field_metatags?.robots}
        currentUrl={siteUrl}
        imageUrl={imageUrl}
      />
      {field_content.map((item) => (
        <Paragraphs key={uid(item)} data={item} />
      ))}
      <div itemScope itemType="https://schema.org/Organization" className="schema-snippet hidden">
        <span itemProp="name">Beneficiary: SIA "Anyfor"</span>
        <span itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
          <span itemProp="addressLocality">Mārupe, Latvia</span>
        </span>
        <span itemProp="email">presale@anyforsoft.com</span>
      </div>
    </Layout>
  );
};

BasicPageTemplate.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    nodePage: PropTypes.shape({
      title: PropTypes.string,
      field_metatags: PropTypes.object,
      field_automatic_breadcrumbs: PropTypes.bool,
      field_alternative_design: PropTypes.bool,
      relationships: PropTypes.shape({
        field_content: PropTypes.array,
        field_image_header: PropTypes.object,
        field_header_paragraph: PropTypes.array,
        field_breadcrumbs: PropTypes.array,
      }),
    }),
  }),
};

export default BasicPageTemplate;

export const query = graphql`
  query($drupal_id: String!) {
    nodePage(drupal_id: { eq: $drupal_id }) {
      ...NodeBasicPage
    }
  }
`;
